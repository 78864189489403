
import { Component, Vue, Watch } from "vue-property-decorator";

import formatDate from "../../plugins/formatDate";
import formatTime from "../../plugins/formatTime";
import StarterStartlist from "../../models/sportevent/StarterStartlist";
import StartGroup from "../../models/sportevent/StartGroup";
import Starter from "../../models/sportevent/Starter";
import StartGroupService from "../../services/sportevent/StartGroupService";
import PrintSettingsService from "../../services/helper/PrintSettingsService";
import Sportevent from "@/models/sportevent/Sportevent";

@Component({
  filters: {
    formatDate,
  },
})
export default class ListStarterComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public starterStartlist: StarterStartlist[] = [];

  public showStarttime = true;

  public sportevent?: Sportevent;

  public sortOptions = [
    { item: "A", name: "Name & Alter & Startzeit" },
    { item: "B", name: "Riege & Start-Pos." },
    { item: "C", name: "Riege & Start-Pos. & Startzeit ausblenden" },
    { item: "D", name: "Verein & Riege & Start-Pos. & Startzeit ausblenden" },
    { item: "E", name: "Verein & Name & Riege & Start-Pos. & Startzeit ausblenden" },
  ];
  public selectedSortOption = "A";

  async getAll(): Promise<void> {
    this.sportevent = this.$store.state.localstore_sportevent.sportevent;
    if (this.sportevent != null) {
      let startgroups = await StartGroupService.getAllBySportevent(true);
      startgroups.forEach((startgroup: StartGroup) => {
        startgroup = new StartGroup(startgroup);
        startgroup.starters.forEach((starter: Starter) => {
          let starterl = new StarterStartlist();
          starterl.id = starter.id;
          starterl.firstname = starter.person.firstname;
          starterl.lastname = starter.person.lastname;
          const dateOfBirth = new Date(starter.person.dateOfBirth);
          starterl.yearOfBirth = dateOfBirth.getFullYear();
          starterl.competitionName =starter.sporteventCompetition.competition.name;
          starterl.startsForSociety = starter.startsForSociety.shortname;
          starterl.startgroupName = startgroup.StartGroupName;
          starterl.startgroupNumber = startgroup.startGroupPosition;
          starterl.positionInStartgroup = starter.positionInStartgroup;
          const startTime = new Date(startgroup.startBlock.startTime);
          starterl.startTime = formatTime(startTime);
          this.starterStartlist.push(starterl);
        });
      });
      this.starterStartlist.sort(this.sortStartlistByName);
      this.showStarttime = true;
      this.loading = false;
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
    PrintSettingsService.setHeader();
  }

  updateSort(): void {
    if (this.selectedSortOption === "A") {
      this.starterStartlist.sort(this.sortStartlistByName);
      this.showStarttime = true;
    } else if (this.selectedSortOption === "B") {
      this.starterStartlist.sort(this.sortStartlistByStartgroup);
      this.showStarttime = true;
    } else if (this.selectedSortOption === "C") {
      this.starterStartlist.sort(this.sortStartlistByStartgroup);
      this.showStarttime = false;
    } else if (this.selectedSortOption === "D") {
      this.starterStartlist.sort(this.sortStartlistBySociety);
      this.showStarttime = false;
    } else if (this.selectedSortOption === "E") {
      this.starterStartlist.sort(this.sortStartlistBySocietyStarterName);
      this.showStarttime = false;
    }
  }

  sortStartlistByName(a: StarterStartlist, b: StarterStartlist): number {
    if(a.lastname < b.lastname) return -1;
    if(a.lastname > b.lastname) return 1;
    if(a.firstname < b.firstname) return -1;
    if(a.firstname > b.firstname) return 1;
    if(a.yearOfBirth < b.yearOfBirth) return -1;
    if(a.yearOfBirth > b.yearOfBirth) return 1;
    if(a.startTime < b.startTime) return -1;
    if(a.startTime > b.startTime) return 1;
    return 0;
  }

  sortStartlistBySociety(a: StarterStartlist, b: StarterStartlist): number { // { item: "D", name: "Verein & Riege & Start-Pos. & Startzeit ausblenden" },
    if(a.startsForSociety < b.startsForSociety) return -1;
    if(a.startsForSociety > b.startsForSociety) return 1;
    if(a.startgroupNumber < b.startgroupNumber) return -1;
    if(a.startgroupNumber > b.startgroupNumber) return 1;
    if(a.positionInStartgroup < b.positionInStartgroup) return -1;
    if(a.positionInStartgroup > b.positionInStartgroup) return 1;
    return 0;
  }

  sortStartlistBySocietyStarterName(a: StarterStartlist, b: StarterStartlist): number { // { item: "D", name: "Verein & Riege & Start-Pos. & Startzeit ausblenden" },
    if(a.startsForSociety < b.startsForSociety) return -1;
    if(a.startsForSociety > b.startsForSociety) return 1;
    if(a.lastname < b.lastname) return -1;
    if(a.lastname > b.lastname) return 1;
    if(a.firstname < b.firstname) return -1;
    if(a.firstname > b.firstname) return 1;
    if(a.startgroupNumber < b.startgroupNumber) return -1;
    if(a.startgroupNumber > b.startgroupNumber) return 1;
    if(a.positionInStartgroup < b.positionInStartgroup) return -1;
    if(a.positionInStartgroup > b.positionInStartgroup) return 1;
    return 0;
  }

  sortStartlistByStartgroup(a: StarterStartlist, b: StarterStartlist): number {
    if(a.startgroupNumber < b.startgroupNumber) return -1;
    if(a.startgroupNumber > b.startgroupNumber) return 1;
    if(a.positionInStartgroup < b.positionInStartgroup) return -1;
    if(a.positionInStartgroup > b.positionInStartgroup) return 1;
    return 0;
  }
}
